import React from "react"
import PageGabarit from "./page";
import styled from "styled-components";

const StyledPageGabarit = styled(PageGabarit)`
  a {
    border: none;
    padding: 0;
    text-decoration: underline;

    &:hover {
      color: white;
      background-color: transparent;
    }
  }

    a > strong {
        font-size: 0.9em;
        display: inline-block;
        padding: 0.5em 2em;
        border: 2px solid #FFFFFF;
        color: #FFFFFF;
        text-transform: uppercase;
        text-decoration: none;
    
        &:hover {
            background-color: #e7d0ba;
            color: #000;
        }
    }
`

const PageTexteGabarit = (props) => <StyledPageGabarit {...props}/>

export default PageTexteGabarit
