import React from "react"
import {MDXProvider} from "@mdx-js/react"
import {MDXRenderer} from "gatsby-plugin-mdx"
import Seo from "../../composants/gabarits/seo";
import BaseGabarit from "./base";
import styled from "styled-components";
import {graphql} from "gatsby";

export const query = graphql`
    fragment PageContent on Mdx {
        body
        frontmatter {
            title
            banner {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            noIndex
        }
    }
`

const StyledLayout = styled(BaseGabarit)`
`

const Wrapper = styled.div`
    max-width: 600px;
    margin-left: auto !important;
    margin-right: auto !important;
    
    p + * {
      margin-top: 1.5em;
    }
    
    h2, h3, h4, h5, h6 {
      margin-bottom: 0; 
      & + * {
        margin-top: 1em;
      }
    }
    
    a {
      font-size: 0.9em;
      margin-top: 20px;
      display: inline-block;
      padding: 0.5em 2em;
      border: 2px solid #FFFFFF;
      color: #FFFFFF;
      text-transform: uppercase;
      text-decoration: none;
      
      &:hover {
          background-color: #e7d0ba;
          color: #000;
      }
    }
`

export default function PageGabarit({className, data: {page: {frontmatter, body}}}) {
    return (
        <StyledLayout banner={frontmatter.banner} className={className} noIndex={frontmatter.noIndex}>
            <Seo title={frontmatter.title}/>
            <h1>{frontmatter.title}</h1>
            <Wrapper>
                <MDXProvider>
                    <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
            </Wrapper>
        </StyledLayout>
    )
}
