import React from "react"
import {graphql} from "gatsby";
import PageTexteGabarit from "./modèles/page-texte";

export const pageQuery = graphql`
    query($id: String!) {
        page: mdx(id: {eq: $id}) {
            ...PageContent
        }
    }
`

const BaseGabarit = (props) => <PageTexteGabarit {...props}/>

export default BaseGabarit
